import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.js";

import { APP_SCROLLER, hardCodeURLs } from "@/app/constants.mjs";
import DownloadButtonWrapper from "@/app/DownloadButtonWrapper.jsx";
import { SymbolGame } from "@/app/symbol-game.mjs";
import ArrowRightIcon from "@/inline-assets/arrow-right2.svg";
import BlitzLogo from "@/inline-assets/blitz-logo.svg";
import WordmarkWithGG from "@/inline-assets/wordmark-with-gg.svg";
import { GENERIC_FEATURES } from "@/marketing/constants/generic-features.mjs";
import { PUBLISHER_INFO_MAP } from "@/marketing/constants/publishers.mjs";
import { GAME_DATA_MAP } from "@/marketing/constants/welcome-game-data.mjs";
import MarketingFooter from "@/marketing/MarketingFooter.jsx";
import * as Styled from "@/marketing/WelcomeRedesign.style.jsx";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

// const USPCTAStyle = {
//   border: "none",
// };

function Welcome() {
  const { t } = useTranslation();

  const {
    parameters: [gameString],
  } = useRoute();

  const gameSymbol = SymbolGame(gameString);
  const gameData = GAME_DATA_MAP[gameSymbol];
  const {
    Icon: PublisherIcon,
    name: publisherName,
    ...iconProps
  } = PUBLISHER_INFO_MAP?.[gameData.compliancy] ?? {};
  return (
    <div className={Styled.pageCss()}>
      <div className="container">
        <div
          className="bg-img"
          style={{ "--bg-url": `url(${gameData.bg.img})` }}
        />
        <NavBar />
        <header className="centered">
          <div className="content">
            <div className="flex column hero-text">
              {gameData?.gameLogo && (
                <img
                  src={gameData.gameLogo.src}
                  className="game-logo"
                  alt={t(...gameData.gameLogo.alt)}
                />
              )}
              <h1>{t(...gameData.headline)}</h1>
              <TrustedCopy
                name={publisherName}
                Icon={PublisherIcon}
                iconProps={iconProps}
                noPublisher={!gameData?.compliancy}
              />
              <div className="flex">
                <DownloadButtonWrapper
                  className="desktop-only"
                  uiLocation="WELCOME_HERO"
                  size="large"
                >
                  {t("common:downloadFree", "Download for Free")}
                </DownloadButtonWrapper>
              </div>
            </div>

            <div className="hero-img">
              {gameData.heroVideo ? (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  poster={gameData.heroVideo.poster}
                >
                  <source src={gameData.heroVideo.webm} type="video/webm" />
                  <source src={gameData.heroVideo.mp4} type="video/mp4" />
                  {t(...gameData.heroVideo.alt)}
                </video>
              ) : (
                <img
                  src={gameData.heroImg.src}
                  alt={t(...gameData.heroImg.alt)}
                />
              )}
            </div>
          </div>
        </header>
        <section className="usp-section">
          <div className="type-callout usps">
            <ul>
              {gameData.usps.map(({ label, icon: Icon, uri }, i) => {
                const Tag = uri ? "a" : "div";
                return (
                  <li key={i}>
                    <Tag href={uri} className="flex gap-3 align-center">
                      {typeof Icon === "string" ? (
                        <img width={32} height={32} aria-hidden src={Icon} />
                      ) : (
                        <Icon width={28} height={28} aria-hidden />
                      )}
                      {t(...label)}
                    </Tag>
                  </li>
                );
              })}
            </ul>
            {/*<DownloadButtonWrapper
              className="desktop-only"
              uiLocation="USP_CTA"
              noIcon
              noClassOverride
              emphasis="low"
              style={USPCTAStyle}
              iconRight={<ArrowRightIcon />}
              size="large"
              textColor="var(--shade0)"
            >
              {t("common:getBlitzCTA", "Get Blitz Now")}
            </DownloadButtonWrapper>*/}
          </div>
        </section>
        <section className="centered">
          <div className="flex column gap-12 content">
            <h2>{t(...gameData.featureTitle)}</h2>
            <div className="feature-blocks">
              {gameData.features.map(
                ({ bgImg, desc, icon: Icon, src, title }, i) => (
                  <div
                    key={i}
                    className="feature"
                    style={{ "--bg-img": `url(${bgImg})` }}
                  >
                    <div className="flex column gap-3 pad-6">
                      <div style={{ color: gameData.color }}>
                        {typeof Icon === "string" ? (
                          <img width={40} height={40} aria-hidden src={Icon} />
                        ) : (
                          <Icon width={40} height={40} aria-hidden />
                        )}
                      </div>
                      <h5 className="type-large-title--bold shade0">
                        {t(...title)}
                      </h5>
                      <p className="type-subtitle shade1">{t(...desc)}</p>
                    </div>
                    <img className="display" src={src} />
                  </div>
                ),
              )}
            </div>
          </div>
        </section>
        <section className="centered">
          <div className="generic-features">
            {GENERIC_FEATURES.filter(
              (feat) => gameData?.compliancy || feat.Icon,
            ).map(({ Icon, desc, title }, i) => (
              <div key={i} className="flex column align-center gap-4 pad-6">
                {Icon ? (
                  <Icon width={32} height={32} />
                ) : (
                  <PublisherIcon width={32} height={32} />
                )}
                <div className="flex column gap-1 align-center">
                  <span className="type-subtitle1">{t(...title)}</span>
                  <span className="type-subtitle shade1 text-center">
                    {t(desc[0], desc[1], { publisher: publisherName })}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className="centered">
          <DownloadButtonWrapper
            className="desktop-only"
            uiLocation="WELCOME_BODY"
            size="large"
          >
            {t("common:downloadFree", "Download for Free")}
          </DownloadButtonWrapper>
        </section>
        <section className="centered">
          <div className="flex column gap-6 faqs">
            <div className="flex column gap-4">
              <h2>{t("welcome:faq", "Frequently Asked Questions")}</h2>
              <span className="type-title shade1">
                {t(
                  "welcome:faqSubtitle",
                  "Still feel like you need to know more? We’ve got you covered.",
                )}
              </span>
            </div>
            <FAQs publisherName={publisherName} />
          </div>
        </section>
        <section className="centered">
          <div className="flex column gap-8 align-center footer">
            <BlitzLogoLoading color="var(--blitz-loading-stroke)" />
            <h2 className="q shade0">
              {t("welcome:downloadCta.title", "Ready to Rank Up?")}
            </h2>
            <p className="type-body1 text-center">
              {t(
                "welcome:downloadCta.body",
                "Blitz simplifies the process of mastering difficult games, helping you improve at every step of your journey to the top.",
              )}
            </p>
            <div className="flex">
              <DownloadButtonWrapper
                className="desktop-only"
                uiLocation="WELCOME_BODY"
                size="large"
              >
                {t("common:downloadFree", "Download for Free")}
              </DownloadButtonWrapper>
            </div>
          </div>
        </section>
      </div>
      <MarketingFooter expanded />
    </div>
  );
}

const Publisher = ({ name, Icon, iconProps }) => (
  <strong className="flex gap-1 align-center">
    <Icon width={24} height={24} {...iconProps} />
    {name}
  </strong>
);

const TrustedCopy = ({
  noPublisher,
  ...p
}: {
  noPublisher: boolean;
  name: string;
  Icon: SVGComponent;
  iconProps: Record<string, unknown>;
}) => (
  <p className="flex column gap-2 wrap type-page-subheader subheader">
    <span className="flex gap-1 wrap">
      <Trans i18nKey="welcome:header.subtitle">
        The Blitz app is trusted by <strong>4,300,000+</strong> users daily.
      </Trans>
      {!noPublisher && (
        <Trans i18nKey="welcome:header.publisherComp">
          <Publisher {...p} /> compliant.
        </Trans>
      )}
    </span>
  </p>
);

const NavBar = () => {
  const { t } = useTranslation();
  const [isScrolled, setScrolled] = useState(false);
  const prevScrolled = useRef(false);

  useEffect(() => {
    const view = globals.document.getElementsByClassName(APP_SCROLLER)[0];
    function scroll(event) {
      const target = event.target;
      const isScrolled = target.scrollTop > 0;
      if (isScrolled !== prevScrolled.current) {
        setScrolled(isScrolled);
        prevScrolled.current = isScrolled;
      }
    }
    view.addEventListener("scroll", scroll);

    return () => {
      view.removeEventListener("scroll", scroll);
    };
  });

  return (
    <nav {...classNames(isScrolled && "is-sticky")}>
      <ul>
        <li>
          <a href="/" className="flex gap-1 align-center">
            <BlitzLogo className="blitz-logo" aria-hidden />
            <WordmarkWithGG height={10} aria-hidden />
            <span className="visually-hidden">
              {t("common:blitz", "Blitz")}
            </span>
          </a>
        </li>
        <li>
          <DownloadButtonWrapper
            className="desktop-only"
            uiLocation="WELCOME_HEADER"
            {...(isScrolled
              ? {}
              : {
                  noClassOverride: true,
                  emphasis: "low",
                  bgColor: "var(--shade1-15)",
                  bgColorHover: "var(--shade1-50)",
                })}
          >
            {t("common:downloadBlitz", "Download Blitz")}
          </DownloadButtonWrapper>
        </li>
      </ul>
    </nav>
  );
};

function FAQs({ publisherName = "publishers" }) {
  const { t } = useTranslation();
  return (
    <ul className="flex column faqs">
      <li>
        <h3>{t("welcome:faq.1", "Is Blitz free to use?")}</h3>
        <span className="type-title shade1">
          <Trans i18nKey="welcome:faq.1a">
            Blitz is entirely free to download and use. We also offer a Premium
            subscription if you’d prefer to not see ads, as well as access extra
            features. Check out our{" "}
            <a className="premium" href="/premium">
              Premium
            </a>{" "}
            page for more details.
          </Trans>
        </span>
      </li>
      <li>
        <h3>{t("welcome:faq.2", "Will I get banned for using Blitz?")}</h3>
        <span className="type-title shade1">
          {t(
            "welcome:faq.2a1",
            "No, using Blitz will not result in your game account being banned. Blitz works closely with {{publisherName}} to ensure our app is fully compliant.",
            { publisherName },
          )}
        </span>
      </li>
      <li>
        <h3>
          {t("welcome:faq.3", "Is Blitz safe to install on my computer?")}
        </h3>
        <span className="type-title shade1">
          {t(
            "welcome:faq.3a",
            "Yes, Blitz is safe to install on your computer. Our team prioritizes the security and privacy of our users. We adhere to industry standards to ensure a secure and reliable experience.",
          )}
        </span>
      </li>
      <li>
        <h3>
          {t("welcome:faq.4", "Is Blitz available in multiple languages?")}
        </h3>
        <span className="type-title shade1">
          {t(
            "welcome:faq.4a",
            "We fully support 17 languages! These include English, Deutsch, Español, Français, Italiano, Nederlands, Português, Polski, Русский, Türkçe, Čeština, Ελληνικά, 한국어日本語, Tiếng Việt, 简体中文, 繁體中文",
          )}
        </span>
      </li>
      <li className="flex">
        <Button
          className="type-title shade1 flex gap-1"
          target="_blank"
          href={hardCodeURLs.BLITZ_SUPPORT}
          iconRight={<ArrowRightIcon />}
          emphasis="low"
        >
          {t("welcome:supportLink", "View all support articles")}
        </Button>
      </li>
    </ul>
  );
}

export function meta() {
  return {
    title: ["home:meta.welcome.title", "Blitz, supercharge your gameplay"],
    description: [
      "home:meta.welcome.description",
      "Expert recommendations, data-driven match analysis, pro builds, guides, overlays, and competitions — personalized for gamers of any skill level.",
    ],
  };
}

export default Welcome;
